import React, { useEffect, useState } from "react";
import styled from "styled-components";
import useGetPriceData2 from "hooks/useGetPriceData2";
import useGetPriceData3 from "hooks/useGetPriceData3";
import { BitcoinIcon, PolygonIcon, EthereumIcon, BinanceIcon } from "../../components/Svg";
import Text from "../../components/Text/Text";

const Container = styled.a`
  display: flex;
  align-items: center;
  margin-right: 4px;
  svg {
    transition: transform 0.3s;
  }
  :hover {
    svg {
      transform: scale(1.2);
    }
  }
`;


const PancakePrice: React.FC<{ btcPriceUsd?: number }> = ({ btcPriceUsd }) => {
  // const ethPriceUsd = useGetPriceData2()
  // const maticPriceUsd = useGetPriceData3()

  const [maticPriceUsd, setMaticPriceUsd] = useState(0)
  const [bnbPriceUsd, setBnbPriceUsd] = useState(0)
  const [sombraPriceUsd, setSombraPriceUsd] = useState(0)
  const [ethereumPriceUsd, setEthereumPriceUsd] = useState(0)

  useEffect(() => {
    fetch('https://api.coingecko.com/api/v3/simple/price?ids=binancecoin&vs_currencies=usd')
      .then((response) => response.json())
      .then((data) => {
        const bscUsd = data.binancecoin.usd
        setBnbPriceUsd(parseFloat(bscUsd))
      })
    fetch('https://api.coingecko.com/api/v3/simple/price?ids=ethereum&vs_currencies=usd')
      .then((response) => response.json())
      .then((data) => {
        const ethereumUsd = data.ethereum.usd
        setEthereumPriceUsd(parseFloat(ethereumUsd))
      })
    fetch('https://api.coingecko.com/api/v3/simple/price?ids=sombra-network&vs_currencies=usd')
      .then((response) => response.json())
      .then((data) => {
        const sombraUsd = data['sombra-network'].usd
        setSombraPriceUsd(parseFloat(sombraUsd))
      })
  }, [])


  return btcPriceUsd ? (
    <>
      <Container>
        <EthereumIcon mr="4px" />
        <Text bold mr="25px">{`$${ethereumPriceUsd.toFixed(2)}`}</Text>
      </Container>
      <Container >
        <BinanceIcon mr="4px" />
        <Text bold mr="25px">{`$${bnbPriceUsd.toFixed(2)}`}</Text>
      </Container>
      <Container>
        <BitcoinIcon mr="4px" />
        <Text bold mr="25px">{`$${sombraPriceUsd.toFixed(2)}`}</Text>
      </Container>
    </>
  ) : <Text />

};

export default PancakePrice;
